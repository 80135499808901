var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_vm._l((_vm.spend_simulation_metrics),function(metrics,metrics_name,index){return [_c('v-col',{key:index,staticClass:"pt-0",attrs:{"cols":"6","md":"3"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(metrics_name))]),_c('v-card-text',[_vm._l((metrics),function(metric,name,idx){return [_c('div',{key:idx},[_vm._v(" "+_vm._s(name)+": "),(metrics_name === 'ROI')?_c('span',{class:[
                    name === 'simulated' &&
                    parseFloat(metric.toFixed(2)) >
                      parseFloat(metrics['current'].toFixed(2))
                      ? 'increase--text'
                      : '',
                    name === 'simulated' &&
                    parseFloat(metric.toFixed(2)) <
                      parseFloat(metrics['current'].toFixed(2))
                      ? 'decrease--text'
                      : '' ]},[_vm._v(" "+_vm._s((((metric * 100).toFixed(0)) + "%"))+" ")]):_c('span',{class:[
                    name === 'simulated' &&
                    parseFloat(metric.toFixed(0)) >
                      parseFloat(metrics['current'].toFixed(0))
                      ? 'increase--text'
                      : '',
                    name === 'simulated' &&
                    parseFloat(metric.toFixed(0)) <
                      parseFloat(metrics['current'].toFixed(0))
                      ? 'decrease--text'
                      : '' ]},[_vm._v(" "+_vm._s(_vm.formatCurrency(metric))+" ")])])]})],2)],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }