<template>
  <v-container>
    <v-row>
      <template
        v-for="(metrics, metrics_name, index) in spend_simulation_metrics"
      >
        <v-col cols="6" md="3" :key="index" class="pt-0">
          <v-card outlined>
            <v-card-title class="font-weight-bold">{{
              metrics_name
            }}</v-card-title>
            <v-card-text>
              <template v-for="(metric, name, idx) in metrics">
                <div :key="idx">
                  {{ name }}:
                  <span
                    v-if="metrics_name === 'ROI'"
                    :class="[
                      name === 'simulated' &&
                      parseFloat(metric.toFixed(2)) >
                        parseFloat(metrics['current'].toFixed(2))
                        ? 'increase--text'
                        : '',
                      name === 'simulated' &&
                      parseFloat(metric.toFixed(2)) <
                        parseFloat(metrics['current'].toFixed(2))
                        ? 'decrease--text'
                        : '',
                    ]"
                  >
                    {{ `${(metric * 100).toFixed(0)}%` }}
                  </span>
                  <span
                    v-else
                    :class="[
                      name === 'simulated' &&
                      parseFloat(metric.toFixed(0)) >
                        parseFloat(metrics['current'].toFixed(0))
                        ? 'increase--text'
                        : '',
                      name === 'simulated' &&
                      parseFloat(metric.toFixed(0)) <
                        parseFloat(metrics['current'].toFixed(0))
                        ? 'decrease--text'
                        : '',
                    ]"
                  >
                    {{ formatCurrency(metric) }}
                  </span>
                </div>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { formatCurrency } from "@/utils";

export default {
  name: "SpendSimulationMetrics",
  methods: {
    formatCurrency,
  },
  computed: {
    spend_simulation_metrics() {
      return this.$store.state.results.spend_simulation_metrics;
    },
  },
};
</script>

<style scoped></style>
